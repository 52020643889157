<template>
	<div class="card">
		<div class="grid formgrid">
			<div class="col-10 xl:col-10">
				<h5>{{$route.name}}</h5>
			</div>
			<div class="col-2">
				<Button label="Pendientes" icon="pi pi-search" class="p-button-primary p-mr-2 p-mb-2" @click="solicDialog = true"/>
			</div>
			<div class="col-12">
				<DataTable :value="citas" :rows="10" :paginator="true" responsiveLayout="scroll">
					<Column field="fecha" header="Fecha" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.dia}}<br>
							{{slotProps.data.fechad}}
						</template>
					</Column>
					<Column field="hora" header="Hora" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.hora}}
						</template>
					</Column>
					<Column field="especialidad" header="Especialidad" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.especialidad}}<br>
							{{slotProps.data.especialista}}
						</template>
					</Column>
					<Column field="espacio" header="Ubicación" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.espacio}}
						</template>
					</Column>
				</DataTable>
			</div>

		</div>

		<Dialog v-model:visible="solicDialog"  style="width: 60vw" header="Citas por Confirmar" :modal="true">
			<DataTable :value="reservas" :rows="10" :paginator="true" responsiveLayout="scroll">
				<Column field="fecha" header="Fecha" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.dia}}<br>
							{{slotProps.data.fechad}}
						</template>
					</Column>
					<Column field="hora" header="Hora">
						<template #body="slotProps">
							{{slotProps.data.hora}}
						</template>
					</Column>
					<Column field="especialidad" header="Especialidad" >
						<template #body="slotProps">
							{{slotProps.data.especialidad}}							
						</template>
					</Column>
				</DataTable>
			<template #footer>
				<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="solicDialog = false"/>
			</template>
		</Dialog>


	</div>
</template>

<script>
import API from "../service/API";
const Consulta = new API('AreaPaciente','MisCitas');

export default {
	data() {
		return {
			citas: [],
			reservas: [],
			products: null,
			solicDialog: false,
			selectedCustomers: null,
			filters: {},
		}
	},
	productService: null,
	themeChangeListener: null,
	created() {
		this.$store.commit('Validar',this.$route);
		this.Mostrar();
	},
	mounted() {

	},

	methods: {
		Mostrar() {
			Consulta.Ini().then(response => {
				//this.$store.state.error = response.result.reservas[0];
				this.citas = response.result.citas
				this.reservas = response.result.reservas
				this.$store.commit('Loading');
			});
		},
	}
}
</script>